import * as React from "react";
import Breadcrumb from "@/components/global/Breadcrumb";
import { fetchApprovalsDataForBenefit } from "@/utils/ViewApprovalsData";
import Center from "@/utils/Center";
import Spinner from "@/utils/Spinner";
import { GenerateApprovalsDataTable } from "@/utils/ViewApprovalsData/GenerateApprovalsDataTable";

const BenefitApprovals = ({ benefitId }) =>
{
	const [loading, setLoading] = React.useState(true)
	const [ approvalsData, setApprovalsData ] = React.useState(false)

	if(!approvalsData) {
		fetchApprovalsDataForBenefit(benefitId, setApprovalsData, setLoading)
	}

	return(
		<div className="mb-lg">
			<Breadcrumb
				items={[
					{ href: '/app/home/', text: 'Home' },
					{ href: '/app/dashboard', text: 'Employer dashboard' },
					{ href: '/app/dashboard/approve-selections/', text: 'Approve Selections' },
				]}
			/>

			{loading && (
				<Center className={`flex flex-col w-full h-150`}>
					<Spinner className="mb-xl" style={{ width: 80, height: 80 }} />
				</Center>
			)}

			{approvalsData && !loading && (
				<>
					<h1 className="text-lg mb-md mt-md">{approvalsData.benefitName} - Selections awaiting approval</h1>
					<GenerateApprovalsDataTable approvalsData={approvalsData} />
				</>
			)}

		</div>
	)
}

export default BenefitApprovals
